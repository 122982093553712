export default {
    data() {
      return {
        websocket: null,
        websocketUrl: '/dispatch'
      }
    },
    // mounted() {
    //   this.websocketInit()
    // },
    methods: {
      websocketInit(url) {
        console.log(process.env.VUE_APP_WEBSOCKET_BASE_URL + (url || this.websocketUrl));
        try {
          this.closeWebsocket()
          this.websocket = new WebSocket(process.env.VUE_APP_WEBSOCKET_BASE_URL + (url || this.websocketUrl))
          // 监听socket连接
          this.websocket.onopen = this.onWebsocketOpen
          // 监听socket错误信息
          this.websocket.onerror = this.onWebsocketError
          // 监听socket消息
          this.websocket.onmessage = this.onWebsocketMessage
          // 监听socket断开
          this.websocket.onclose = this.onWebsocketClose
        } catch (e) {
          console.log(e)
        }
      },
      closeWebsocket() {
        this.websocket && this.websocket.close()
      },
      onWebsocketClose(e) {
        this.websocket = null
      }
  
    },
    destroyed () {
       this.closeWebsocket();
    },
  }
  