<template>
    <div>
        <el-dialog :title="title" :visible.sync="show" width="40%" @close="$emit('update:dialogVisible', false)">
            <el-form ref="form" :model="form" label-width="100px">
                <el-form-item label="企业号等级" v-if="type == 'enterprise'">
                    <el-radio-group v-model="form.val" class="height">
                        <p v-for="(item, index) in $store.state.config && $store.state.config.enterpriseGrade" :key="index">
                            <el-radio :label="item.id">{{ item.name }}</el-radio>
                        </p>
                        <!-- <p style="color: #fda400; font-size:12px">
              审核前确认客户已经完成支付或转账。请商务和客服自行跟客户确认会员类型。避免误操作！！！！！
            </p> -->
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="VIP到期时间" v-if="type == 'enterprise' && [2, 3, 4].includes(form.val)">
                    <div-picker v-model="form.vipEndTime" size="small"> </div-picker>
                </el-form-item>
                <el-form-item label="会员等级" v-if="type == 'user'">
                    <el-radio-group v-model="form.val" class="height">
                        <p v-for="(item, index) in $store.state.config && $store.state.config.userGrade" :key="index">
                            <el-radio :label="item.id">{{ item.name }}</el-radio>
                        </p>
                        <!-- <p style="color: #fda400; font-size:12px">
              审核前确认客户已经完成支付或转账。请商务和客服自行跟客户确认会员类型。避免误操作！！！！！
            </p> -->
                    </el-radio-group>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="clear">取 消</el-button>
                <el-button type="primary" @click="editGrade">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        dialogVisible: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "修改等级",
        },
        row: {
            type: [String, Object],
            default: "",
        },
        type: {
            type: [String, Object],
            default: "enterprise",
        },
        reqUrl: {
            type: String,
            default: "/admin/Enterprise/editGrade",
        },
    },
    data() {
        return {
            form: {
                val: "",
            },
            show: false,
        };
    },

    watch: {
        dialogVisible(val) {
            this.show = this.dialogVisible;
            // console.log(this.row)
            if (this.row.egId == 1) {
                this.form.val = 1;
            } else {
                this.form.val = "";
            }
            if (val && this.type == "user") {
                this.form.val = this.row.ugId;
                console.log(this.form.val);
            }
        },
        // 'form.val'(val) {
        //   if (val == 1) {
        //     this.form.vipEndTime = ''
        //   }
        // }
    },
    created() {
        // if (this.row.egId == 0) {
        //   console.log(1)
        // }
    },
    methods: {
        clear() {
            this.$emit("update:dialogVisible", false);
        },
        async editGrade() {
            const { data: res } = await this.$http.post(this.reqUrl, {
                id: this.row.id,
                egId: this.form.val,
                ugId: this.form.val,
                vipEndTime: this.form.vipEndTime,
            });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.$emit("update:dialogVisible", false);
                this.$emit("getlist");
            }
        },
        beforesub() {
            this.$confirm("选择付费等级前请确认客户是否支付，或者此客户不需要支付！！！", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.submit();
                })
                .catch(() => {});
        },
        submit() {
            console.log(this.row.id);
            // return
            this.$http
                .post("/admin/Enterprise/check", {
                    id: this.row.id,
                    status: 3,
                    remark: "",
                    egId: this.form.val,
                })
                .then(({ data: res }) => {
                    if (res.errorCode == 200) {
                        this.$message.success(res.message);
                        this.$emit("update:dialogVisible", false);
                        this.$emit("getlist");
                    }
                });
        },
    },
};
</script>

<style lang="less" scoped>
// .el-dialog__header {
//   text-align: center;
// }
.height {
    margin-left: 20px;
}
.height > p {
    margin-bottom: 20px;
}
.height > p label {
    margin-bottom: 10px;
}
</style>
