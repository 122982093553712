<template>
    <div>
        <nav class="input" style="width: 150px">
            <el-select v-model="type" placeholder="类型" size="medium">
                <el-option label="全部" :value="-1" />
                <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
        </nav>
        <nav class="input" style="width: 150px">
            <el-select v-model="business" placeholder="行业" size="medium">
                <el-option label="全部" :value="-1" />
                <el-option v-for="item in businessList" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
        </nav>
        <nav class="input" style="width: 150px">
            <el-select v-model="subBusiness" placeholder="二级行业" size="medium">
                <el-option label="全部" :value="-1" />
                <el-option v-for="item in subBusinessList" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
        </nav>
    </div>
</template>

<script>
export default {
    props: {
        form: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            typeList: [],
            businessList: [],
            subBusinessList: [],
        };
    },
    watch: {
        type(newValue, oldValue) {
            console.log(newValue);
            this.business = "";
            this.subBusiness = "";
            this.getType("1");
        },
        business(newValue, oldValue) {
            this.subBusiness = "";
            this.getType("2");
        },
    },
    methods: {
        async getType(val) {
            if (val == 1) {
                var { data: res } = await this.$http.get(
                    `/admin/EnterpriseCategory/getList?keyword=&page=1&pageSize=100&order=&typeId=${this.type}&businessId=`
                );
                this.businessList = res.data.list;
                return;
            }
            if (val == 2) {
                var { data: res } = await this.$http.get(
                    `/admin/EnterpriseCategory/getList?keyword=&page=1&pageSize=100&order=&typeId=${this.type}&businessId=${this.business}`
                );
                this.subBusinessList = res.data.list;
                return;
            }
            var { data: res } = await this.$http.get(
                "/admin/EnterpriseCategory/getList?keyword=&page=1&pageSize=100&order=&typeId=0&businessId="
            );
            this.typeList = res.data.list;
        },
    },
    computed: {
        type: {
            get() {
                return this.form.type;
            },
            set(type) {
                this.$emit("update:form", { ...this.form, type });
            },
        },
        business: {
            get() {
                return this.form.business;
            },
            set(business) {
                this.$emit("update:form", { ...this.form, business });
            },
        },
        subBusiness: {
            get() {
                return this.form.subBusiness;
            },
            set(subBusiness) {
                this.$emit("update:form", { ...this.form, subBusiness });
            },
        },
    },
    mounted() {
        this.getType();
    },
};
</script>

<style lang="scss" scoped></style>
